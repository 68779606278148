import extend from 'deep-extend';

export default class {

    constructor(options) {
        const defaults = {
            searchBar: '.search-bar',
            searchLink: '.main-nav .search-link',
            btnClose: '.btn-close'
        };

        this.options = extend({}, defaults, options);
        this.$elem = document.querySelector('.search-bar');
        this.$searchLink = document.querySelector(this.options.searchLink);
        this.$input = document.getElementById('search-input-box');
        
        if (!this.$elem) {
            return;
        }

        this.$btnClose = this.$elem.querySelector(this.options.btnClose);
        this.$btnClose.addEventListener('click', () => {
            this.$elem.classList.add('hide-on-desktop');
        });

        this.$searchLink.addEventListener('click', (e) => {
            e.preventDefault();
            this.$elem.classList.remove('hide-on-desktop');
            this.$input.focus();
        });
    }
}