export default function getLatestUnitPriceData(data) {
    let tableData = {
        columns: ['Investment Option', 'Date', 'Unit Price'],
        rows: []
    };
    for (let i = 0; i < data.length; i++) {
        let columns = [];
        columns.push(data[i].name);
        let rowData = data[i].data[data[i].data.length-1];

        let date = new Date(rowData[0]);
        let jsDate = date.getDate();
        let jsMonth = date.getMonth() + 1;
        let formattedDate = (jsDate < 10 ? '0'+jsDate:jsDate) + '/' + (jsMonth < 10 ? '0'+jsMonth:jsMonth) + '/' + date.getFullYear();
        columns.push(formattedDate);
        columns.push(rowData[1]);
        tableData.rows.push(columns);
    }
    return tableData;
}