export default function parsePerformanceChartData(data) {
    let columnNames = data.filter((item) => {return item.visible;}).map((item) => item.name);

    columnNames.unshift('Date');

    var tableData = {
        columns: columnNames,
        rows: []
    };
    // Process the columns
    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].data.length; j++) {
            let date = new Date(data[i].data[j][0]);
            let jsDate = date.getDate();
            let jsMonth = date.getMonth() + 1;
            let formattedDate = (jsDate < 10 ? '0'+jsDate:jsDate) + '/' + (jsMonth < 10 ? '0'+jsMonth:jsMonth) + '/' + date.getFullYear();
            let value = data[i].data[j][1];
            let columnPosition = (i+1);

            if((tableData.rows.length-1) < j) {
                tableData.rows.push([formattedDate]);
            }

            tableData.rows[j][columnPosition] = value;
        }
    }

    tableData.rows.reverse();

    return tableData;
}