export default class {

    constructor(options, statemanager) {
        const defaults = {
            btnOffCanvas: '.btn-offcanvas'
        };

        this.$elem = document.querySelector('#global-header');
        this.$body = document.body;

        if (!this.$elem){
            return;
        }

        /*
        Define nodes / elements
         */
        this.$btnOffCanvas = this.$elem.querySelector(defaults.btnOffCanvas);

        /*
        Event binding
         */
        this.bindEvents();
    }

    toggleClasses(){

        //Toggle button
        if(this.$btnOffCanvas.classList.contains('nav-open')){
            this.$btnOffCanvas.classList.remove('nav-open');
        }  else {
            this.$btnOffCanvas.classList.add('nav-open');
        }

        //Toggle body element; used for fixing nav and overflow
        if(this.$body.classList.contains('nav-open')){
            this.$body.classList.remove('nav-open');
        }  else {
            this.$body.classList.add('nav-open');
        }
    }

    bindEvents(){
        this.$btnOffCanvas.addEventListener('click', () =>{
            Mjolnir.Components.GlobalNav.toggleNav();
            this.toggleClasses();
        });
    }
}
