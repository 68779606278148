import extend from 'deep-extend';
import Choices from 'choices.js';

export default class {

    constructor(elem, options) {
        const defaults = {
            select: '.user-select',
            btnSubmit: '.btn-submit'
        };

        if (!elem){
            return;
        }

        this.options = extend({}, defaults, options);

        /*
         Define nodes / elements
         */
        this.$elem = elem;
        this.$select = this.$elem.querySelector(this.options.select);
        this.$btnSubmit = this.$elem.querySelector(this.options.btnSubmit);

        /*
        Event binding
         */
        /*
        Rendering of select input is dependent on CMS populated values
        Check the select is in the DOM first
         */

        if (this.$select){
            const choices = new Choices(this.$select, {
                searchEnabled: false,
                shouldSort: false,
                itemSelectText: ''
            });

            this.$btnSubmit.addEventListener('click', ()=>{
                let targetUrl = this.$select.options[this.$select.selectedIndex].value;
                window.location = targetUrl;
            });
        } else {
            return;
        }
    }
}
