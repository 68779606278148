import extend from 'deep-extend';

export default class {

    constructor(elem, options) {
        const defaults = {
            chartEmbedId: '.chart-embed',
            fallBackHex: '#ff000',
            hexCodes: [
                {
                    name: 'Liquid Alternatives',
                    newColour: '#F5F5F5',
                },
                {
                    name: 'Growth Alternatives',
                    newColour: '#666666',
                },
                {
                    name: 'Cash',
                    newColour: '#DDDDDD',
                },
                {
                    name: 'Private Credit',
                    newColour: '#38424D',
                },
                {
                    name: 'International Listed Property ', //NOTE! This space at end is REQUIRED
                    newColour: '#F18EA5',
                },
                {
                    name: 'Infrastructure',
                    newColour: '#999999',
                },
                {
                    name: 'Australian Direct Property',
                    newColour: '#342DA4',
                },
                {
                    name: 'Private Equity',
                    newColour: '#FFE3D8',
                },
                {
                    name: 'Short Term Fixed Interest',
                    newColour: '#FF634F',
                },
                {
                    name: 'Australian Equities',
                    newColour: '#D6F8E5',
                },
                {
                    name: 'International Equities',
                    newColour: '#9ADF2A',
                },
                {
                    name: 'Bonds',
                    newColour: '#F6B111',
                }
            ]
        };

        this.options = extend({}, defaults, options);

        if (!elem){
            return;
        }

        /*
         Define nodes / elements / etc
         */
        window.Highcharts = Highcharts;
        this.$elem = elem;
        this.$chartElem = this.$elem.querySelector(this.options.chartEmbedId);
        let apiUrl = elem.dataset.apiUrl;
        let forceProxy = this.$elem.dataset.forceProxy;

        if(forceProxy === 'true'){
            apiUrl = 'https://cors.bridged.cc/' + apiUrl;
        }

        this.fetchData(apiUrl);
    }

    fetchData(apiUrl){

        fetch(apiUrl, { headers: { "Content-Type": "application/json; charset=utf-8" }})
            .then(res => res.json()) // parse response as JSON (can be res.text() for plain response)
            .then(response => {
                /*
                Reconfigure the data hex values
                 */
                let updatedData = this.updateHexCodes(response);
                this.renderPieChart(updatedData);
            });
    }

    updateHexCodes(data){
        let newData = data;

        /*
        This could be more efficient, and flexible
         */
        for (var obj of newData) {

            let matchedColourObj = this.options.hexCodes.filter(colourObj => {
                return colourObj.name === obj.name
            });
            if (matchedColourObj.length !== 0){
                obj.color = matchedColourObj[0].newColour;
            } else {
                obj.color = this.options.fallBackHex;
            }
        }
        return newData;
    }

    renderPieChart(earnRateData){
        const earningChart = new Highcharts.Chart({
            chart: {
                renderTo: this.$chartElem,
                type: 'pie',
                margin: [0, 0, 0, 0],
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            plotOptions: {
                pie: {
                    size:'100%',
                    dataLabels: {
                        enabled: false,
                        format: '<b>{point.name}</b>: {point.percentage} %'
                    }
                }
            },
            series: [{
                type: 'pie',
                name: 'Asset Allocation',
                data: earnRateData
            }],
            tooltip: {
                borderWidth: 0,
                shadow: false,
                useHTML: true,
                backgroundColor: "#003822",
                style: {
                    padding: 10,
                    fontSize: '14px',
                    color: '#ffffff'
                },
                formatter: function() {
                    return this.point.name + '<b>: ' + Highcharts.numberFormat(this.y, 2, '.', ',') + '%</b>';
                }

            }
        });
    }
}