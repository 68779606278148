import extend from 'deep-extend';

export default class {

    constructor(elem, options) {
        const defaults = {
            accordionItem: '.accordion-item',
            btnAccordionTrigger: '.btn-accordion-trigger',
            accordionContent: '.accordion-content',
            openFirst: false,
            allowMultipleOpen: false
        };

        if (!elem){
            return;
        }

        this.options = extend({}, defaults, options);

        /*
         Define nodes / elements
         */
        this.$elem = elem;
        this.$accordionItems = this.$elem.querySelectorAll(this.options.accordionItem);
        this.activeAccordion = null;

        /*
        Function inits
         */
        this.initAccordionItems();
    }

    closeAccordionItem(targetElem){
        if (targetElem.classList.contains('active')) {
            let targetContent = targetElem.querySelectorAll(this.options.accordionContent)[0];

            targetElem.classList.remove('active');
            targetContent.classList.remove('active');

            this.activeAccordion = null;
        }
    }

    openAccordionItem(parentElem, contentElem){
        parentElem.classList.add('active');
        contentElem.classList.add('active');

        this.activeAccordion = parentElem;
    }

    toggleAccordionItem(parentElem, contentElem){

        if (this.options.allowMultipleOpen === false) {

            if (this.activeAccordion !== null){

                let isTargetActive = parentElem.isEqualNode(this.activeAccordion);
                if (isTargetActive === true) {

                    this.closeAccordionItem(parentElem);

                } else {

                    this.closeAccordionItem(this.activeAccordion);
                    this.openAccordionItem(parentElem, contentElem);
                }
            } else {
                this.openAccordionItem(parentElem, contentElem);
            }
        } else {

            if (parentElem.classList.contains('active')) {
                this.closeAccordionItem(parentElem, contentElem);

            } else {
                this.openAccordionItem(parentElem, contentElem);
            }
        }
    }

    initAccordionItems(){
        let self = this;
        this.$accordionItems.forEach(function (elem, index) {
            let $accordionContent = elem.querySelectorAll(self.options.accordionContent)[0];
            let $btnAccordionTrigger = elem.querySelectorAll(self.options.btnAccordionTrigger)[0];

            if ($accordionContent){

                /*
                Event binding for each item
                 */
                $btnAccordionTrigger.addEventListener('click', ()=>{
                    self.toggleAccordionItem(elem, $accordionContent)
                });

            } else {
                return;
            }
        });

        if (this.options.openFirst === true){
            let $firstItem = this.$accordionItems[0];
            let $firstItemContent = $firstItem.querySelectorAll(self.options.accordionContent)[0];

            this.openAccordionItem($firstItem, $firstItemContent)
        }
    }
}
