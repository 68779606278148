import extend from 'deep-extend';
import parsePerformanceChartData from '../helpers/parse-performance-chart-data.es6';

export default class {

    constructor(elem, options) {
        const defaults = {
            tableWrap: '.table-responsive-wrap',

        };

        this.options = extend({}, defaults, options);

        if (!elem) {
            return;
        }

        this.$elem = elem;
        let apiUrl = elem.dataset.apiUrl;
        let apiAggregate = elem.dataset.apiAggregateUrls;
        let forceProxy = this.$elem.dataset.forceProxy;

        /*
         Define nodes / elements
         */
        this.$tableParent = this.$elem.querySelector(this.options.tableWrap);

        if (typeof apiAggregate !== 'undefined') {
            this.fetchAggregate(apiAggregate, forceProxy);
        } else {
            if (forceProxy === 'true') {
                apiUrl = 'https://cors.bridged.cc/' + apiUrl;
            }

            this.fetchData(apiUrl);
        }

    }

    fetchData(apiUrl) {
        fetch(apiUrl, {headers: {"Content-Type": "application/json; charset=utf-8"}})
            .then(res => res.json()) // parse response as JSON (can be res.text() for plain response)
            .then(response => {
                let tableData = parsePerformanceChartData(response);
                this.renderTableES6(tableData);
            });
    }

    fetchAggregate(endpoints, forceProxy) {
        this.aggregateData = [];
        let endpointList = endpoints.split(';');
        for (let i = 0; i < endpointList.length; i++) {
            let endpointParts = endpointList[i].split(',').map((item) => item.trim());
            let apiUrl = endpointParts[1];
            if (forceProxy === 'true') {
                apiUrl = 'https://cors.bridged.cc/' + apiUrl;
            }
            this.aggregateData[i] = {
                complete: false,
                label: endpointParts[0],
                data: []
            };
            fetch(apiUrl, {headers: {"Content-Type": "application/json; charset=utf-8"}})
                .then(res => res.json()) // parse response as JSON (can be res.text() for plain response)
                .then(response => {
                    this.aggregateData[i].complete = true;
                    this.aggregateData[i].data = response;
                    if (this.aggregateData.filter((i) => {
                        return !i.complete
                    }).length === 0) {
                        let processedData = this.processAggregate(this.aggregateData);
                        this.renderTableES6(processedData);
                    }
                });
        }
    }

    processAggregate(data) {
        let dateRowMap = {};
        let processedData = {
            columns: ['Date'],
            rows: []
        };
        for (let i = 0; i < data.length; i++) {
            processedData.columns.push(data[i].label);
            for (let j = 0; j < data[i].data.length; j++) {
                let date = data[i].data[j].date;
                let jsDateObj = new Date(date);
                let jsDate = jsDateObj.getDate();
                let jsMonth = jsDateObj.getMonth() + 1;
                let formattedDate = (jsDate < 10 ? '0'+jsDate:jsDate) + '/' + (jsMonth < 10 ? '0'+jsMonth:jsMonth) + '/' + jsDateObj.getFullYear();
                let price = data[i].data[j].price;
                if (typeof dateRowMap[date] === 'undefined') {
                    dateRowMap[date] = j;
                    processedData.rows.push([formattedDate]);
                }
                processedData.rows[dateRowMap[date]][i + 1] = price;
            }
        }
        processedData.rows.reverse();
        return processedData;
    }

    renderTableES6(data) {
        let {columns, rows} = data;
        const tableStart = `<table class="performance-table">`;
        const tableHeadStart = `<thead><tr>`;
        const tableHeadEnd = `</tr></thead>`;
        const tableBodyStart = `<tbody>`;
        const tableBodyEnd = `</tbody>`;
        const tableEnd = `</table>`;

        function headerCell(column) {
            return `
                <th class="header-cell">${column}</th>
            `;
        }

        function bodyCell(cell) {
            return `
                <td>${cell}</td>
            `;
        }

        function bodyRow(row) {
            return `
                <tr>
                    ${row.map(bodyCell).join("")}
                </tr>
            `;
        }

        this.$tableParent.innerHTML = `
            ${tableStart}
                ${tableHeadStart}
                    ${columns.map(headerCell).join("")}
                ${tableHeadEnd}
                ${tableBodyStart}
                    ${rows.map(bodyRow).join("")}
                ${tableBodyEnd}
            ${tableEnd}
        `;

        let table = this.$tableParent.querySelector('.performance-table');
        new Tabulator(table, {
            layout: 'fitColumns',
            headerSort: false,
            pagination: 'local',
            paginationSize: 10,
            paginationSizeSelector: false,
            selectable: false
        });
    }
}
