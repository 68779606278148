import MicroModal from 'micromodal';  // es6 module
import extend from 'deep-extend';
import LocalStorageManager from '../utils/localstoragemanager.es6';


import 'url-polyfill';

export default class {

    constructor(options) {
        const defaults = {
            modalId: 'popup-modal',
            btnClose: '.btn-close',
            localStorageName: 'AS-popup-modal',
            localStorageExpiryTime: 7*24*60*60*1000 // Expiry  after 7 days
        };

        this.options = extend({}, defaults, options);
        this.$elem = document.getElementById(this.options.modalId);
        
        if (!this.$elem){
            return;
        }

        /*
         Define nodes / elements
         */
        this.$btnClose = this.$elem.querySelector(this.options.btnClose);

        /*
        Local Storage + Referrer checks
         */
        let localStorageCheck = LocalStorageManager.getWithExpiry(this.options.localStorageName);
        
        if(localStorageCheck === null){
            this.showModal();
            LocalStorageManager.setWithExpiry(this.options.localStorageName, true, this.options.localStorageExpiryTime);
        }

        this.$btnClose.addEventListener('click', () => {
            this.closeModal();
        });
    }
    
    showModal(){
        MicroModal.show(this.options.modalId);
    }

    closeModal(){
        MicroModal.close(this.options.modalId);
    }
}