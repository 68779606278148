import extend from 'deep-extend';
import hoverintent from 'hoverintent';
import 'element-closest-polyfill';

export default class {

    constructor(options, statemanager) {
        const defaults = {
            btnTopLevel: '.top-lvl-item .text',
            btnAccordion: '.btn-accordion-trigger',
            listItemTopLevel: '.main-nav > li',
            subNav: '.sub-nav',
            accordionAnimateSpeed: 300,
            hoverIntent: {
                timeout: 500,
                interval: 50
            }
        };

        this.state = {
            open: false
        };

        this.options = extend({}, defaults, options);
        this.$elem = document.querySelector('#global-nav');
        this.activeAccordion = null;
        this.activeSubNav = null;

        if (!this.$elem){
            return;
        }

        /*
         Define nodes / elements
         */
        let self = this;
        this.$listItemTopLevel = this.$elem.querySelectorAll(this.options.listItemTopLevel);
        this.$btnTopLevel = this.$elem.querySelectorAll(this.options.btnTopLevel);
        this.$btnAccordion = this.$elem.querySelectorAll(this.options.btnAccordion);

        /*
        Bind statemanager events as required
         */
        statemanager.addOnEnter('mobile', function () {
            self.initSubNavAccordions();
        });

        statemanager.addOnEnter('desktop', function () {
            self.resetSubNavAccordions();
        });

        this.bindEvents(statemanager);
    }

    /*
    Top level mobile off canvas functionality
    Note: this is for ENTIRE nav element
    */

    // Opens the mobile nav
    openNav(){
        this.$elem.classList.add('open');
        this.state.open = true;
    }

    // Closes the mobile nav
    closeNav(){
        if (this.$elem.classList.contains('open')) {
            this.$elem.classList.remove('open');
            this.state.open = false;
        }

        if(this.activeAccordion){
            setTimeout(() => this.closeSubNavAccordion(this.activeAccordion, this.activeBtn));
        }

    }

    // Uses above methods to toggle the mobile nav
    toggleNav(){
        if (this.state.open === false){
            this.openNav();
        } else {
            this.closeNav();
        }
    }


    /*
    Mobile accordion functionality
    */

    // Opens a target subnav accordion
    openSubNavAccordion(targetSubNav, targetBtn){
        let openHeight = targetSubNav.getAttribute('data-open-height');
        targetSubNav.setAttribute('style', 'height: '+openHeight+'px');
        targetSubNav.classList.add('active');
        targetBtn.classList.add('active');
        this.activeAccordion = targetSubNav;
        this.activeBtn = targetBtn;
    }

    // Close a target subnav accordion
    closeSubNavAccordion(targetSubNav, targetBtn){
        targetSubNav.setAttribute('style', 'height: 0px');
        targetSubNav.classList.remove('active');
        targetBtn.classList.remove('active');
        this.activeAccordion = null;
        this.activeBtn = null;
    }

    // Uses above methods to toggle a subnav accordion
    toggleSubNavAccordion(targetSubNav, targetBtn){
        let isTargetActive = targetSubNav.classList.contains('active');

        if(isTargetActive){
            this.closeSubNavAccordion(targetSubNav, targetBtn);
        } else {
            this.openSubNavAccordion(targetSubNav, targetBtn);
        }
    }

    // Sets required attributes for accordion elements
    initSubNavAccordions(){
        this.$btnAccordion.forEach(function (trigger, index) {
            let $parentListIem = trigger.closest('li');
            let $subNav = $parentListIem.querySelector('.sub-nav');

            if ($subNav){
                let subNavDefaultHeight = $subNav.offsetHeight;
                $subNav.classList.remove('active');
                $subNav.setAttribute('data-open-height', subNavDefaultHeight);
                $subNav.setAttribute('style', 'height: 0px');

            } else {
                return;
            }
        });
    }

    // Clears any required attributes from above methods
    resetSubNavAccordions(){
        this.activeSubNav = null;
        this.$btnAccordion.forEach(function (trigger, index) {

            let $parentListIem = trigger.closest('li');
            let $subNav = $parentListIem.querySelector('.sub-nav');

            if ($subNav){
                $subNav.classList.remove('active');
                $subNav.setAttribute('style', 'height: auto');

            } else {
                return;
            }
        });
    }

    /*
    Desktop subnav functionality
     */
    // Shows a target subnav
    openSubNav(targetSubNav){
        targetSubNav.classList.add('active');
        this.activeSubNav = targetSubNav;
    }

    // Hides a target subnav
    closeSubNav(targetSubNav){
        targetSubNav.classList.remove('active');
        this.activeSubNav = null;
    }

    /*
    Common shared function to bind trigger events
     */
    bindEvents(statemanager){
        let self = this;

        /*
        Bind accordion events
         */
        this.$btnAccordion.forEach(function (trigger, index) {

            let $parentListIem = trigger.closest('li');
            let $subNav = $parentListIem.querySelector('.sub-nav');

            if ($subNav){

                trigger.addEventListener('click', ()=>{

                    let isMobile = statemanager.ssm.isActive('mobile');

                    if(isMobile){
                        self.toggleSubNavAccordion($subNav, trigger)
                    }
                });

            } else {
                return;
            }
        });

        /*
        Bind mouseenter events for top level items
         */
        this.$listItemTopLevel.forEach(function (trigger, index) {

            let $subNav = trigger.querySelector('.sub-nav');
            if ($subNav){

                hoverintent(trigger,
                    function() {
                        let isMobile = statemanager.ssm.isActive('mobile');

                        if(!isMobile){
                            if (self.activeSubNav !== null){
                                self.closeSubNav(self.activeSubNav)
                            }

                            self.openSubNav($subNav);
                            self.activeSubNav = $subNav;
                        }
                    }, function() {
                        let isMobile = statemanager.ssm.isActive('mobile');

                        if(!isMobile){

                            self.closeSubNav($subNav);
                            self.activeSubNav = null;
                        }
                    }).options(self.options.hoverIntent);

            } else {
                return;
            }
        });
    }
}


