import extend from 'deep-extend';
import intersectionObserver from 'intersection-observer';
export default class {

    constructor(elem, options) {
        const defaults = {
            select: '.user-select',
            btnSubmit: '.btn-submit'
        };

        if (!elem || window.Mjolnir.adminMode === true ){
            return;
        }

        this.options = extend({}, defaults, options);

        /*
         Define nodes / elements
         */
        this.$elem = elem;
        let animationDelay = 0;
        if (this.$elem.dataset.animationDelay){
            animationDelay = this.$elem.dataset.animationDelay;
        }


        if(this.$elem.dataset.animateOnscroll === 'true'){
            this.setScrollObserver(this.$elem.dataset.scrollThreshold);
        } else {
            this.timer = setTimeout(() => {
                this.setLoadedState();
            }, animationDelay);
        }
    }


    setLoadedState(){
        this.$elem.dataset.animated = true;
        clearTimeout(this.timer);
    }

    setScrollObserver(threshold) {

        let newThreshold = parseFloat(threshold);
        let observer = new IntersectionObserver(onIntersection, {
            root: null,
            threshold: newThreshold // percentage of target's visible area. Triggers "onIntersection"
        });

        function onIntersection(entries, opts) {
            entries.forEach((entry) => {
                if(entry.isIntersecting === true){
                    entry.target.setAttribute('data-animated', 'true');
                }
            });
        }

        observer.observe(this.$elem)
    }
}