import extend from 'deep-extend';
import getLatestUnitPriceData from '../helpers/get-latest-unit-price-data.es6';

export default class {

    constructor(elem, options) {
        const defaults = {
            tableWrap: '.table-responsive-wrap',
        };

        this.options = extend({}, defaults, options);

        if (!elem){
            return;
        }

        this.$elem = elem;
        let apiUrl = elem.dataset.apiUrl;
        let apiAggregate = elem.dataset.apiAggregateUrls;
        let forceProxy = 'true'; // TODO this was not parametised

        /*
         Define nodes / elements
         */
        this.$tableParent = this.$elem.querySelector(this.options.tableWrap);

        //Begin
        if (typeof apiAggregate !== 'undefined') {
            this.fetchAggregate(apiAggregate, forceProxy);
        } else {
            if (forceProxy === 'true') {
                apiUrl = 'https://cors.bridged.cc/' + apiUrl;
            }

            this.fetchData(apiUrl);
        }
        
    }


    fetchData(apiUrl){

        fetch(apiUrl, { headers: { "Content-Type": "application/json; charset=utf-8" }})
            .then(res => res.json()) // parse response as JSON (can be res.text() for plain response)
            .then(response => {
                let tableData = getLatestUnitPriceData(response);
                this.renderTable(tableData);
            });
    }

    fetchAggregate(endpoints, forceProxy) {
        this.aggregateData = [];
        let endpointList = endpoints.split(';');
        for (let i = 0; i < endpointList.length; i++) {
            let endpointParts = endpointList[i].split(',').map((item) => item.trim());
            let apiUrl = endpointParts[1];
            if (forceProxy === 'true') {
                apiUrl = 'https://cors.bridged.cc/' + apiUrl;
            }
            this.aggregateData[i] = {
                complete: false,
                label: endpointParts[0],
                data: []
            };
            fetch(apiUrl, {headers: {"Content-Type": "application/json; charset=utf-8"}})
                .then(res => res.json()) // parse response as JSON (can be res.text() for plain response)
                .then(response => {
                    this.aggregateData[i].complete = true;
                    this.aggregateData[i].data = response;
                    if (this.aggregateData.filter((i) => {
                        return !i.complete
                    }).length === 0) {
                        let processedData = this.processAggregate(this.aggregateData);
                        this.renderTable(processedData);
                    }
                });
        }
    }

    processAggregate(data) {
        let processedData = {
            columns: ['Investment Option', 'Date', 'Unit Price'],
            rows: []
        };
        for (let i = 0; i < data.length; i++) {
            let lastRow = data[i].data[data[i].data.length-1];

            let jsDateObj = new Date(lastRow.date);
            let jsDate = jsDateObj.getDate();
            let jsMonth = jsDateObj.getMonth() + 1;
            let formattedDate = (jsDate < 10 ? '0'+jsDate:jsDate) + '/' + (jsMonth < 10 ? '0'+jsMonth:jsMonth) + '/' + jsDateObj.getFullYear();
            
            processedData.rows.push([data[i].label, formattedDate, lastRow.price]);
        }
        
        
        
        return processedData;
    }


    renderTable(data){
        let {columns, rows} = data;
        const tableStart = `<table class="performance-table">`;
        const tableHeadStart = `<thead><tr>`;
        const tableHeadEnd = `</tr></thead>`;
        const tableBodyStart = `<tbody>`;
        const tableBodyEnd = `</tbody>`;
        const tableEnd = `</table>`;

        function headerCell(column) {
            return `
                <th class="header-cell">${column}</th>
            `;
        }

        function bodyCell(cell){
            return `
                <td>${cell}</td>
            `;
        }

        function bodyRow(row) {
            return `
                <tr>
                    ${row.map(bodyCell).join("")}
                </tr>
            `;
        }

        this.$tableParent.innerHTML = `
            ${tableStart}
                ${tableHeadStart}
                    ${columns.map(headerCell).join("")}
                ${tableHeadEnd}
                ${tableBodyStart}
                    ${rows.map(bodyRow).join("")}
                ${tableBodyEnd}
            ${tableEnd}
        `;
    }
}
