import extend from 'deep-extend';
import flatpickr from "flatpickr";


export default class {

    constructor(elem, options) {
        const defaults = {
            chartEmbedId: '#chart-embed',
            keyListElem: '.key ul',

            hexCodes: {
                highGrowth: '#003822',
                balancedGrowth: '#51BB18',
                balanced: '#36A5B3',
                conservative: '#342DA4',
                managedCash: '#F18EA5',
                growth: '#F6B111' //Note: this isn't in design - I've made a judgement call
            }
        };

        this.options = extend({}, defaults, options);

        if (!elem){
            return;
        }

        /*
         Define nodes / elements / etc
         */
        window.Highcharts = Highcharts;
        this.$elem = elem;
        this.$keyList = this.$elem.querySelector(this.options.keyListElem);
        let apiUrl = elem.dataset.apiUrl;
        let forceProxy = this.$elem.dataset.forceProxy;

        if(apiUrl == null || undefined){
            return;
        }
        if(forceProxy === 'true'){
            apiUrl = 'https://cors.bridged.cc/' + apiUrl;
        }

        this.fetchData(apiUrl);
    }


    fetchData(apiUrl){

        fetch(apiUrl, { headers: { "Content-Type": "application/json; charset=utf-8" }})
            .then(res => res.json()) // parse response as JSON (can be res.text() for plain response)
            .then(response => {
                /*
                Reconfigure the data hex values
                 */
                let updatedData = this.updateHexCodes(response);
                this.renderKey(updatedData);
                this.renderLineChart(updatedData);
            });
    }

    updateHexCodes(data){
        let newData = data;

        /*
        This could be more efficient, and flexible
         */
        for (var obj of newData) {

            if(obj.name === 'High Growth'){
                obj.color = this.options.hexCodes.highGrowth;
            } else if(obj.name === 'Balanced Growth'){
                obj.color = this.options.hexCodes.balancedGrowth;
            } else if(obj.name === 'Balanced'){
                obj.color = this.options.hexCodes.balanced;
            } else if(obj.name === 'Conservative'){
                obj.color = this.options.hexCodes.conservative;
            } else if(obj.name === 'Managed Cash'){
                obj.color = this.options.hexCodes.managedCash;
            } else if(obj.name === 'Growth'){
                obj.color = this.options.hexCodes.growth;
            }
        }
        return newData;
    }

    initCustomDatePicker(chart){

        let minDate = chart.xAxis[0].getExtremes().min;
        let maxDate = chart.xAxis[0].getExtremes().max;
        let inputStartDate = document.querySelector('input.highcharts-range-selector[name="min"]');
        let inputEndDate = document.querySelector('input.highcharts-range-selector[name="max"]');

        flatpickr(inputStartDate, {
            minDate: minDate,
            maxDate: maxDate,
            defaultDate: minDate,
        });

        flatpickr(inputEndDate, {
            minDate: minDate,
            maxDate: maxDate,
            defaultDate: maxDate,
        });
    }

    renderLineChart(earnRateData){
        let earningRateChartID = this.$elem.querySelector(this.options.chartEmbedId);

        let self = this;
        let earningChart = new Highcharts.StockChart({
            chart: {
                renderTo: earningRateChartID,
                plotBackgroundColor: '#fcfcfc',
                type: 'line',
                events: {
                    load: function () {
                        self.chartLoaded(this);

                    },
                }
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    day: '%d %b\'%y',
                    week: '%b\'%y',
                    month: '%Y',
                    year: '%Y'
                },
                endOnTick: true,
                lineColor: '#FFFFFF',
                lineWidth: 2,
                tickLength: 0,
                labels: {
                    style: {fontSize: '14px', fontWeight: 'bold', fontFamily: '"Lato", sans-serif', color: '#656565'},
                    //autoRotation: false
                    rotation: -45
                },
            },
            yAxis: {
                gridLineWidth: 0,
                plotLines: [{
                    color: '#cbcbcb',
                    width: 1,
                    value: 0
                }],
                startOnTick: false,
                opposite: false,
                labels: {align: 'right', style: {fontSize: '14px', fontWeight: 'bold', fontFamily: '"Lato", sans-serif', color: '#656565'}, formatter: function () {
                        return this.value + '%';
                    }},
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                line: {
                    lineWidth: 2,
                    shadow: false,
                    marker: {
                        symbol: "circle",
                        enabled: false,
                        states: {
                            hover: {
                                enabled: true,
                                radius: 4,
                                lineWidth: 1,
                                fillColor: null,
                                lineColor: null
                            }
                        }
                    }
                },
                series: {
                    compare: 'percent',
                    turboThreshold: 2000
                }

            },
            scrollbar: {
                enabled: 1
            },
            rangeSelector: {
                enabled: 1,
                //inputEnabled:1,
                allButtonsEnabled:1,
                inputDateFormat:'%d/%m/%Y',
                inputEditDateFormat:'%d/%m/%Y',
                inputBoxWidth: 94,
                inputBoxHeight: 18,
                buttons: [{
                    type: 'month',
                    count: 1,
                    text: '1m'
                }, {
                    type: 'month',
                    count: 3,
                    text: '3m'
                }, {
                    type: 'month',
                    count: 6,
                    text: '6m'
                }, {
                    type: 'year',
                    count: 1,
                    text: '1y'
                }, {
                    type: 'all',
                    text: 'All'
                }]
            },
            navigator: {
                enabled: true,
                maskInside: false,
                // outlineColor: '#4a7729',
                outlineWidth: 1,
                margin: 15,
                height: 60,
                maskFill: 'rgba(190,206,178,0.6)',
                series: {
                    color: '#4c3249',
                    fillOpacity: 0.4,
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        day: '%d %b\'%y',
                        week: '%b\'%y',
                        month: '%Y',
                        year: '%Y'
                    },
                    endOnTick: true,

                    labels: {
                        style: {fontSize: '13px', fontWeight: 'bold', fontFamily: '"Lato", sans-serif', color: '#3e3e3e'},
                    },
                    gridLineWidth: 0,
                }
            },
            series: earnRateData,
            title: {
                text: false
            },
            tooltip: {
                crosshairs: true,
                borderWidth: 0,
                shared: true,
                shadow: false,
                useHTML: true,
                backgroundColor: "#F5F5F5",
                style: {
                    padding: 10,
                    color: '#003822',
                    zIndex: 999999,
                    fontSize: '14px'

                },
                formatter: function () {
                    var d = Date.fromISO(this.x);
                    d.setMinutes(d.getMinutes() + d.getTimezoneOffset() + 660);
                    d.setHours(d.getHours() + 12);
                    var e = Date.parse(d);

                    var s =
                        '<div class="tooltip">' +
                        '   <p class="date">'
                                + Highcharts.dateFormat('%d/%m/%Y', e) + '' +
                        '   </p>' +
                        '   <table>';

                    s +=
                        '<tr>' +
                        '   <td class="strong">Option</td>' +
                        '   <td class="strong">Return * </td>' +
                        '   <td class="strong">' + 'Unit Price' + '</td>' +
                        '</tr>';

                    var t = 0;
                    $.each(this.points, function (i, point) {

                        var l =
                            '<tr>' +
                            '   <td class="chart-element">'
                                    + point.series.name +
                            '   </td>';


                        l +=
                            '<td class="chart-element">'
                                + Highcharts.numberFormat(point.point.change, 2, '.', ',') + '%'+
                            '</td>';


                        var v =
                            '<td class="chart-element">'
                                +Highcharts.numberFormat(point.y, 6, '.', ',') +
                            '</td>' +
                            '</tr>';
                        s += l + v;
                        t += Number(point.y);
                    });
                    s += "</div>";
                    return s;

                }
            }
        });
    }

    renderKey(data) {
        function listItemTemplate(obj) {
            return `
                <li>
                    <div class="icon-wrap" style="background-color: ${obj.color}">
                        <svg class="icon default">
                            <use xlink:href="/static/svg/ui-icon-sprite.svg#check"></use>
                        </svg>
                    </div>
                    <span>${obj.name}</span>
                </li>
            `;
        }

        this.$keyList.innerHTML = `
            ${data.map(listItemTemplate).join("")}
        `;
    }
    chartLoaded(chart){
        this.initCustomDatePicker(chart);
    }
}


/*
I'm leaving this as is for now - it requires further investigation to confirm exactly what its doing and why
 */

var D = new Date('2011-06-02T09:34:29+02:00');
if (!D || +D !== 1307000069000) {
    Date.fromISO = function (s) {
        var day, tz,
            rx = /^(\d{4}\-\d\d\-\d\d([tT ][\d:\.]*)?)([zZ]|([+\-])(\d\d):(\d\d))?$/,
            p = rx.exec(s) || [];
        if (p[1]) {
            day = p[1].split(/\D/);
            for (var i = 0, L = day.length; i < L; i++) {
                day[i] = parseInt(day[i], 10) || 0;
            }
            ;
            day[1] -= 1;
            day = new Date(Date.UTC.apply(Date, day));
            if (!day.getDate())
                return NaN;
            if (p[5]) {
                tz = (parseInt(p[5], 10) * 60);
                if (p[6])
                    tz += parseInt(p[6], 10);
                if (p[4] == '+')
                    tz *= -1;
                if (tz)
                    day.setUTCMinutes(day.getUTCMinutes() + tz);
            }
            return day;
        }
        return NaN;
    }
}
else {
    Date.fromISO = function (s) {
        return new Date(s);
    }
}