import extend from 'deep-extend';
import Cookies from 'js-cookie';

export default class {

    constructor(options) {
        const defaults = {
            btnClose: '.btn-close',
            cookieName: 'AS-notification-banner'
        };

        this.options = extend({}, defaults, options);
        this.$elem = document.querySelector('#notification-bar');

        if (!this.$elem){
            return;
        }

        /*
         Define nodes / elements
         */
        this.$btnClose = this.$elem.querySelector(this.options.btnClose);
        this.$body = document.body;

        let CookieCheck = Cookies.get(this.options.cookieName);

        if(CookieCheck === undefined ){
            this.showBanner();
        } else if(window.Mjolnir.adminMode === true) {
            this.showBanner();
        }
        this.$btnClose.addEventListener('click', ()=>{
            if(window.Mjolnir.adminMode === false){
                this.closeBanner();
            }
        });
    }

    saveCookie(value){
        Cookies.set(this.options.cookieName, value, {
            expires: 7,
            secure: true
        });
    }

    showBanner(){
        this.$elem.classList.add('active');
        this.$body.classList.add('notification-active');
    }

    closeBanner(){
        this.saveCookie(true);
        this.$elem.classList.remove('active');
        this.$body.classList.remove('notification-active');
    }
}
