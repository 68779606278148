/*
Utilities, Polyfills and shims
 */
import '../_core/polyfills/is-nan.es6';
import picturefill from 'picturefill';
import svg4everybody from 'svg4everybody';
import 'nodelist-foreach-polyfill';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import StateManager from './../_core/utils/statemanager.es6';

/*
Vendors and 3rd Party libraries
 */
import Swiper from 'swiper';
import SwiperCore, {
    Navigation,
    Pagination
} from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);

/*
Components
 */
import GlobalHeader from './../_core/components/global-header.es6';
import GlobalNav from './../_core/components/global-nav.es6';
import NotificationBar from '../_core/components/notification-bar.es6';
import PopupModal from '../_core/components/popup-modal.es6';
import SearchBar from '../_core/components/search-bar.es6';


/*
Widgets
 */
import Accordion from './../_core/widgets/accordion.es6';
import Wayfinder from './../_core/widgets/wayfinding-semantic-search.es6';
import AnimatedWidget from './../_core/widgets/animated-widget.es6';
import VideoPlayer from './../_core/widgets/video-player.es6';


/*
LEGACY
Performance Charts and visualizations
 */
import PerformanceChart from '../_core/performance/legacy/performance-line-chart.es6';
import UnitPriceHistoryTable from '../_core/performance/legacy/unit-price-history-table.es6';
import UnitPriceLatestTable from '../_core/performance/legacy/unit-price-latest-table.es6';
import AssetAllocationPieChart from '../_core/performance/legacy/asset-allocation-piechart.es6';


picturefill();
svg4everybody();


let Components = {
    GlobalHeader,
    GlobalNav,
    NotificationBar,
    PopupModal,
    SearchBar
};

let Widgets = {
    Accordion,
    Wayfinder,
    AnimatedWidget,
    VideoPlayer
};

let LegacyVisualizations = {
    PerformanceChart,
    UnitPriceHistoryTable,
    UnitPriceLatestTable,
    AssetAllocationPieChart,
};

function loadComponent(name, options) {
    if (typeof Components[name] !== 'undefined') {
        return new Components[name](options, StateManager);
    } else {
        console.error('Component load error - ', name);
        return false;
    }
}

function loadWidget(name, elem, options) {
    if (typeof Widgets[name] !== 'undefined') {
        return new Widgets[name](elem, options);
    } else {
        console.error('Widget load error - ', name);
        return false;
    }
}

function loadLegacyVisualization(name, elem, options) {
    if (typeof LegacyVisualizations[name] !== 'undefined') {
        return new LegacyVisualizations[name](elem, options);
    } else {
        console.error('Legacy Visualizations load error - ', name);
        return false;
    }
}

window.Mjolnir = window.Mjolnir || {};
window.Mjolnir.loadWidget = loadWidget;
window.Mjolnir.loadComponent = loadComponent;
window.Mjolnir.loadLegacyVisualization = loadLegacyVisualization;
window.Mjolnir.Components = [];
window.Mjolnir.adminMode = false;

//Global Components
Mjolnir.Components.GlobalHeader = new Mjolnir.loadComponent('GlobalHeader');
Mjolnir.Components.GlobalNav = new Mjolnir.loadComponent('GlobalNav');
Mjolnir.Components.NotificationBar = new Mjolnir.loadComponent('NotificationBar');
Mjolnir.Components.PopupModal = new Mjolnir.loadComponent('PopupModal');
Mjolnir.Components.SearchBar = new Mjolnir.loadComponent('SearchBar');

StateManager.run();


/*
Global Widget initializers
 */

document.addEventListener("DOMContentLoaded", function () {
    /*
    Video Module
    */
    var videoElements = document.querySelectorAll('.video-wrapper');
    videoElements.forEach(function (elem, index) {
        let videoWidget = Mjolnir.loadWidget('VideoPlayer', elem, {
            hideContentOnPlay: true,
            autoplay: false,
            muted: false,
            hideControls: true
        });
    });

    /*
    Accordion Widget
    JS: /AS.Presentation/js/_core/widgets/accordion.es6
    View: /AS.Web/Components/Widgets/AccordionWidget/_AccordionWidget.cshtml
     */
    var accordionElems = document.querySelectorAll('.accordion');
    accordionElems.forEach(function (elem, index) {

        let accordionWidget = Mjolnir.loadWidget('Accordion', elem, {
            openFirst: false,
            allowMultipleOpen: true,
        });
    });

    /*
    Wayfinding / Semantic Widget
    JS: /AS.Presentation/js/_core/widgets/accordion.es6
    View: /AS.Web/Components/Widgets/AccordionWidget/_AccordionWidget.cshtml
     */
    var wayfindingElems = document.querySelectorAll('.wayfinding-semantic-search');
    wayfindingElems.forEach(function (elem, index) {
        let wayfindingWidget = Mjolnir.loadWidget('Wayfinder', elem);
    });

    /*
    Featured Content Sliders
     */
    var featuredContentSlider = document.querySelectorAll('.featured-content-slider');
    featuredContentSlider.forEach(function (elem, index) {

        let swiperContainer = elem.querySelector('.swiper-container');
        let swiper = new Swiper(swiperContainer, {

            loop: true,
            slidesPerView: 1,
            spaceBetween: 24,

            breakpoints: {
                768: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                },
            },

            navigation: {
                nextEl: '.btn-swiper-next',
                prevEl: '.btn-swiper-prev'
            },
        });
    });

    /*
    On load animations
     */
    var animatedWidgets = document.querySelectorAll('[data-animated-widget="true"]');
    animatedWidgets.forEach(function (elem, index) {
        let animatedWidget = Mjolnir.loadWidget('AnimatedWidget', elem);
    });

    /*
    LEGACY
    Visualization and tables
     */
    var initLegacyPerformance = true;
    if (initLegacyPerformance === true) {
        /*
        Performance Table Pagination via Tabulator
         */
        var performanceTable = document.querySelectorAll('.performance-table');
        performanceTable.forEach(function (elem, index) {
            if (elem.dataset.paginated) {
                let formatedTable = new Tabulator(elem, {
                    layout: 'fitColumns',
                    headerSort: false,
                    pagination: 'local',
                    paginationSize: 10,
                    paginationSizeSelector: false,
                    selectable: false
                });
            }
        });

        /*
        Unit Price History Table
         */
        var unitPriceHistoryTable = document.querySelectorAll('.unit-price-history-table');
        unitPriceHistoryTable.forEach(function (elem, index) {
            let unitPriceTable = Mjolnir.loadLegacyVisualization('UnitPriceHistoryTable', elem);
        });

        /*
        Latest Unit Price Table
         */
        var unitPriceLatestTable = document.querySelectorAll('.unit-price-latest-table');
        unitPriceLatestTable.forEach(function (elem, index) {
            let unitPriceTable = Mjolnir.loadLegacyVisualization('UnitPriceLatestTable', elem);
        });

        /*
        Performance Line Chart
         */
        var performanceLineChart = document.querySelectorAll('.performance-line-chart');
        performanceLineChart.forEach(function (elem, index) {
            let performanceChart = Mjolnir.loadLegacyVisualization('PerformanceChart', elem);
        });

        /*
        Asset Allocation Pie Chart
         */
        var assetAllocationElem = document.querySelectorAll('.asset-allocation-pie-chart');
        assetAllocationElem.forEach(function (elem, index) {
            let assetAllocationPieChart = Mjolnir.loadLegacyVisualization('AssetAllocationPieChart', elem);
        });
    }


    var liveChatTrigger = document.querySelectorAll('.btn-start-chat');
    liveChatTrigger.forEach(function (elem, index) {
        elem.addEventListener('click', (e) => {
            window.webChatGlobal["showChatbox"]();
            e.preventDefault();
        });
    });
});